import React from "react"

import Job from "../components/job"
import {
  JobFeature,
  JobRole,
  JobRequirements,
} from "../components/job-components"

const JobGraphicDesigner = () => {
  return (
    <Job
      title="Graphic Designer"
      location="Linz or Vienna, Austria"
      workingTime="Geringfügig"
      geringfuegig={true}
      startDate="Starting January 2022"
    >
      <JobRole>
        <JobFeature>
          Enhance our brand voice and messaging for graphic design pieces
        </JobFeature>
        <JobFeature>
          Design product illustrations, logos, and other graphics
        </JobFeature>
        <JobFeature>
          Create visualizations and templates which convey our branding for
          sales, social media and website content
        </JobFeature>
        <JobFeature>
          Illustrate design ideas using storyboards, process flows and sitemaps
        </JobFeature>
        <JobFeature>
          Develop UI mockups and wireframes that clearly illustrate how sites
          function and look like
        </JobFeature>
      </JobRole>
      <JobRequirements>
        <JobFeature>
          Proficiency with Photoshop, Illustrator and similar software suites
        </JobFeature>
        <JobFeature>Exceptional creativity and innovative thinking</JobFeature>
        <JobFeature>Time management and organizational skills</JobFeature>
        <JobFeature>Attention to detail</JobFeature>
        <JobFeature>Communication skills</JobFeature>
        <JobFeature>Fluent in English</JobFeature>
        <JobFeature>
          Beneficial: Experience with Scrum or other agile project management
          frameworks
        </JobFeature>
        <JobFeature>
          Beneficial: Experience with HTML/CSS/JS and Markdown
        </JobFeature>
      </JobRequirements>
      <h3 className="my-5">What we offer</h3>
      <JobFeature>
        Exciting, modern tech stacks and work culture that you can actively
        shape
      </JobFeature>
      <JobFeature>
        Work with our young and international team on high-tech solutions and
        services
      </JobFeature>
      <JobFeature>
        Career opportunities as part of a growing startup in an emerging market
      </JobFeature>
      <JobFeature>
        Flexible working hours and possibility of remote work
      </JobFeature>
      <JobFeature>
        An office at Tabakfabrik Linz or near MuseumsQuartier in Vienna
      </JobFeature>
    </Job>
  )
}

export default JobGraphicDesigner
